import React from 'react'
import { Container } from 'react-bootstrap'

// import SiteAccentBtn from '../../../components/SiteAccentBtn'
import SkillsShowcase from '../SkillsShowcase'

import './styles.scss'

const Profile = () => {
    return (
        <div className='profile' data-aos="fade-in" data-aos-delay="400">
            <Container style={{ textAlign: 'center' }}>
                <div className='profile__logo'>
                    <svg width='107.666' height='83.946' viewBox='0 0 107.666 83.946'>
                        <g transform='translate(-263.699 -394.6)'>
                            <path
                                d='M333.063,417.936l-26.715.322a17.592,17.592,0,0,0-12.875,5.15,18.219,18.219,0,0,0-4.989,13.2,17.466,17.466,0,0,0,4.989,12.875,17.117,17.117,0,0,0,12.553,5.15h18.347v23.336H306.509c-12.231,0-22.37-4.023-30.578-11.909S263.7,448.352,263.7,436.6a39.751,39.751,0,0,1,12.553-29.773q12.553-12.07,30.9-12.07l43.453-.161h0C350.283,394.922,334.833,402,333.063,417.936Z'
                                transform='translate(0 0)'
                                fill='rgb(32, 157, 220)'
                            />
                            <path
                                d='M312.517,454.507c-.483,0-8.851.161-9.817.161v23.5c29.129,1.931,36.371-7.564,36.371-7.564a27.119,27.119,0,0,0,8.53-20.117c0-5.472-2.092-9.656-5.472-15.45l-1.931-3.219c-1.448-2.575-2.092-4.506-2.092-6.276a6.55,6.55,0,0,1,1.931-4.828,6.945,6.945,0,0,1,4.506-2.092V395.283a35.178,35.178,0,0,0-5.794-.483,26.368,26.368,0,0,0-19.473,8.047,27.5,27.5,0,0,0-8.208,19.8,37.77,37.77,0,0,0,5.472,19.151c2.253,4.023,3.38,6.6,3.38,7.886C319.92,449.84,320.242,454.185,312.517,454.507Z'
                                transform='translate(23.765 0.122)'
                                fill='rgb(32, 157, 220)'
                            />
                        </g>
                    </svg>
                </div>
                <div className='profile__copy-wrapper'>
                    <h1 className='profile__name'>Chantal St Louis</h1>
                    <h2 className='profile__occupation'>Front-end Developer</h2>
                    <p className='profile__desc'>
                        A pro-active thinker with an unparalleled work ethic. Equipped with a
                        passion for problem solving and
                        learning new things.
                    </p>
                    
                    {/*<p className='profile__desc'>Currently working @ Fat Beehive</p>*/}

                    {/*<SiteAccentBtn btnLink={'/projects'} btnText={'Projects'} />*/}
                </div>

                <SkillsShowcase />
            </Container>
        </div>
    )
}

export default Profile